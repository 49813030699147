var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center huge-margin-bottom"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"mt-8"},[_c('div',{staticClass:"flex justify-center items-center space-x-12"},_vm._l((_vm.boxes),function(box,index){return _c('div',{key:index,staticClass:"relative w-40 h-4 rounded-lg border-2 p-4 flex items-center justify-center cursor-pointer",class:{
                    'bg-gray-100 border-dotted': _vm.selectedBox !== index,
                    'bg-white border-solid border-black': _vm.selectedBox === index
                },on:{"click":function($event){return _vm.selectBox(index)}}},[_c('span',{staticClass:"text-md font-medium"},[_vm._v(_vm._s(box))]),(index < _vm.boxes.length - 1)?_c('div',{staticClass:"line-divider-box"}):_vm._e()])}),0)]),_c('div',{staticClass:"mt-8"},[_c('div',{staticClass:"flex flex-wrap justify-center gap-6 w-full"},_vm._l((_vm.apps),function(app,index){return _c('sa-gradient-rounded-box',{key:index,attrs:{"text":app.text,"is-selected":_vm.selectedBox === app.box}})}),1)]),_c('div',{staticClass:"mt-8"},[_c('div',{staticClass:"w-4/5 container text-center items-center justify-self-center p-4 bg-white rounded-lg shadow-lg"},[_c('div',{staticClass:"w-full justify-center flex flex-row space-x-8"},[_c('sa-button',{on:{"click":function($event){return _vm.$router.push({ name: 'register.index' })}}},[_vm._v("Pruebalo gratis")]),_c('el-button',[_vm._v("Conoce mas")])],1),_c('div',{staticClass:"mt-8 text-sm text-gray-400"},[_vm._v(" Inicia tu prueba gratuita de 15 dias. Sin necesidad de ingresar tu tarjeta de credito. ")])])]),_vm._m(2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-5xl"},[_c('div',{staticClass:"flex justify-center items-baseline space-x-2"},[_c('span',[_vm._v("Te presentamos el mejor software")]),_c('img',{staticClass:"align-bottom",attrs:{"src":require("@/assets/img/hammer.png"),"width":"120px","alt":"Imagen"}})]),_c('div',{staticClass:"mt-2"},[_vm._v(" para administrar tu "),_c('strong',[_vm._v("taller de reparación")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-2xl mt-4"},[_c('div',{staticClass:"text-gray-400"},[_vm._v(" Toma el control de tu negocio con una sola aplicación. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-16"},[_c('div',{staticClass:"huge-font-size text-white"},[_vm._v("TODO EL CONTROL")]),_c('div',{staticClass:"relative"},[_c('img',{staticClass:"screenshot",attrs:{"src":require("@/assets/img/introduction.png")}})])])
}]

export { render, staticRenderFns }