<template>
    <div class="text-center huge-margin-bottom">
        <div class="text-5xl">
            <div class="flex justify-center items-baseline space-x-2">
                <span>Te presentamos el mejor software</span>
                <img src="@/assets/img/hammer.png" width="120px" alt="Imagen" class="align-bottom" />
            </div>
            <div class="mt-2">
                para administrar tu <strong>taller de reparación</strong>
            </div>
        </div>

        <div class="text-2xl mt-4">
            <div class="text-gray-400">
                Toma el control de tu negocio con una sola aplicación.
            </div>
        </div>

        <div class="mt-8">
            <div class="flex justify-center items-center space-x-12">
                <div v-for="(box, index) in boxes" :key="index"
                    class="relative w-40 h-4 rounded-lg border-2 p-4 flex items-center justify-center cursor-pointer"
                    :class="{
                        'bg-gray-100 border-dotted': selectedBox !== index,
                        'bg-white border-solid border-black': selectedBox === index
                    }" @click="selectBox(index)">
                    <span class="text-md font-medium">{{ box }}</span>

                    <!-- Línea entre los boxes (excepto el último) -->
                    <div v-if="index < boxes.length - 1" class="line-divider-box"></div>
                </div>
            </div>
        </div>

        <div class="mt-8">
            <div class="flex flex-wrap justify-center gap-6 w-full">
                <sa-gradient-rounded-box v-for="(app, index) in apps" :key="index" :text="app.text"
                    :is-selected="selectedBox === app.box" />
            </div>
        </div>

        <div class="mt-8">
            <div class="w-4/5 container text-center items-center justify-self-center p-4 bg-white rounded-lg shadow-lg">
                <div class="w-full justify-center flex flex-row space-x-8">
                    <sa-button @click="$router.push({ name: 'register.index' })">Pruebalo gratis</sa-button>
                    <el-button>Conoce mas</el-button>
                </div>
                <div class="mt-8 text-sm text-gray-400">
                    Inicia tu prueba gratuita de 15 dias. Sin necesidad de ingresar tu tarjeta de credito.
                </div>
            </div>
        </div>

        <div class="mt-16">
            <div class="huge-font-size text-white">TODO EL CONTROL</div>
            <div class="relative">
                <img class="screenshot" src="@/assets/img/introduction.png" />
            </div>
        </div>
    </div>
</template>
<script>
import { APPS, FEATURES } from '../configuration';

export default {
    name: 'Introduction',
    data() {
        return {
            selectedBox: 0,
            boxes: FEATURES.map(({ name }) => name),
            apps: APPS,
        };
    },
    methods: {
        selectBox(index) {
            this.selectedBox = index;
        },
    },
};
</script>
<style scoped>
.line-divider-box {
    position: absolute;
    width: 50px;
    height: 1px;
    background-color: black;
    right: -50px;
    opacity: 0.5;
}
.screenshot {
    width: -webkit-fill-available;
    position: absolute;
    top: -72px;
}
</style>
